import { disableUnits, enableUnits } from '@/api/pmunit';

const disableUnit = {
	id: 'disableUnit',
	selectionType: 'multiple',
	label: 'pmunit.actions.disableUnit',
	functionality: 'UPDATE_PMUNIT',
	checkAvailability: function (unit) {
		return unit && unit.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmunit.actions.disableUnit');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmunitid: registries[i].pmunitid
			});
		}

		const data = await disableUnits(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableUnit = {
	id: 'enableUnit',
	selectionType: 'multiple',
	label: 'pmunit.actions.enableUnit',
	functionality: 'UPDATE_PMUNIT',
	checkAvailability: function (unit) {
		return unit && unit.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmunit.actions.enableUnit');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmunitid: registries[i].pmunitid
			});
		}

		const data = await enableUnits(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableUnit, enableUnit]
};
